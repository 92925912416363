:root {
	--primary-color: #e9208b;
}

body {
	padding: 0;
	overflow-x: hidden !important;
	color: var(--primary-color);
}

@font-face {
	font-family: "Open Sans";
	src: url(../fonts/OpenSans-Regular.ttf);
}

html,
body {
	font-family: 'Open Sans' !important;
	font-size: 1rem !important;
	color: #000 !important;
}

.logo {
	width: 102px;
	height: 39px;
}

#navbar {
	box-shadow: 0 3px 7px #39393933;
}

.menuList li {
	list-style-type: none;
	float: left;
	display: table;
	padding: 5px 20px 0;
}

.menuList li a {
	color: #000;
	padding: 0;
	display: table-cell;
	vertical-align: middle;
	border-bottom: 2px solid transparent;
}

.menuList li a.active {
	border-bottom: 2px solid #fecc2f;
}

.showcase-msg-box {
	width: 300px;
	position: relative;
}

/* background */
.bgPrimary {
	background: var(--primary-color) !important;
}

.bg-form-control {
	background: #ededed !important;
}

.bgSecondary {
	background: #ccc !important;
}

/* widths */
.width-25 {
	width: 25px;
}

.w-30 {
	width: 30% !important;
}

.w-35 {
	width: 35% !important;
}

.w-60 {
	width: 60% !important;
}

.w-70 {
	width: 70% !important;
}

/* misc */
:focus {
	outline: 0 !important;
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	font-size: 0.7rem;
}

::-moz-placeholder {
	/* Firefox 19+ */
	font-size: 0.7rem;
}

:-ms-input-placeholder {
	/* IE 10+ */
	font-size: 0.7rem;
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 768px) {
	.logo {
		width: 152px;
		height: 59px;
	}

}

.font-large {
	font-size: 1.2rem;
}

.font-x-medium {
	font-size: 0.85rem !important;
}

.font-medium {
	font-size: 0.7rem !important;
}

.modal-header .close {
	padding: 0 !important;
	margin: 0 !important;
}

/* Register Modal */
#register-form .form-control {
	margin: 0.75rem 0;
}

.divider {
	width: 1px;
	background: var(--primary-color);
}

label[for="category1"],
label[for="category2"],
label[for="category3"],
label[for="category4"] {
	font-size: 0.75rem;
}

.dropzone {
	text-align: center;
	padding: 20px;
	border: 2px dashed var(--primary-color);
	color: var(--primary-color);
	background-color: #ededed;
}

.progressBar {
	background: #ccc !important;
}

input[type=date]::after {
	content: '*';
	color: red;
	font-size: 0.8rem;
}

input[type="text"]:active,
input[type="number"]:active,
input[type="email"]:active,
input[type="tel"]:active,
input[type="date"]:active,
input[type="text"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="date"]:focus {
	outline: none !important;
	border-radius: 0;
	box-shadow: none !important;
	border-bottom: 2px solid #1d99fd !important;
}

button:focus,
button:active {
	outline: none !important;
	border-radius: 0;
	box-shadow: none !important;
}

.form-control {
	font-size: 0.85rem !important
}

.h6,
h6 {
	font-weight: 600 !important;
}

.footer-block {
	font-family: "Open Sans";
	color: #666;
	font-size: 0.8rem;
	padding: 1rem;
	margin: 0 auto !important;
}

.pm-0 {
	padding: 0;
	margin: 0;
}

.pl-0 {
	padding-left: 0 !important;
}

.pr-0 {
	padding-right: 0 !important;
}

.ml-0 {
	margin-left: 0 !important;
}

.mr-0 {
	margin-right: 0 !important;
}

.mlr-10 {
	margin: 0 7% !important;
}

.footer-block .footer-links a {
	color: #e9208b;
	margin: 0 5px;
}

.donate-right {
	margin-left: 3rem !important;
}

.mb-img {
	position: absolute;
	right: -6%;
	top: -10%;
	width: 18%;
}

.arrow-img {
	position: absolute;
	left: -25%;
	top: -9%;
	width: 22%;
}

h1::before {
	content: '';
	position: absolute;
	width: 6%;
	top: 120%;
	left: 46%;
	border-bottom: 3px solid #e9208b;
}

.carousel-1.carousel-caption {
	right: 0;
	left: auto;
	background: rgba(255, 255, 255, 0.8);
	width: 43%;
	text-align: left;
	padding: 2rem 0 2rem 9rem;
	color: #321e50;
	bottom: 80px;
}

.c-1-img {
	width: 41%;
	position: absolute;
	left: -7%;
	top: -56%;
}

.categories-padding {
	padding-left: 2rem;
}

.partners img {
	width: 120px;
}

.about-misaal-logo {
	width: 80%;
}

.media-article {
	cursor: pointer;
}

.media-article a {
	color: #000;
}

.media-article h6 {	
	margin: 16px 0 0 0;	
}

.media-article h6 div {
	margin-top: 4px;
	color: gray;	
	font-size: 0.7rem;
}

.media-article a:hover {
	color: #000;
	text-decoration: none;
}

.logo-placeholder span {
	display: block;
	font-style: italic;
	font-weight: 600;
	font-size: 1.6rem;
	line-height: 1rem;
}

.navbar-brand span {
	display: block;
	font-style: italic;
	font-weight: 600;
	font-size: 0.95rem;
	line-height: 0.7rem;
}

.social-links {
	margin: 0 0 0 37% !important;
}

.let-us-logo {
	width: 60%;
	margin: 0 auto;
}

@media (max-width: 1024px) {

	.carousel-1.carousel-caption {
		width: 50% !important;
		bottom: 50px !important;
	}

	.c-1-img {
		width: 39% !important;
		top: -35% !important;
		left: -6% !important;
	}

	.menuList li {
		padding: 5px 13px 0;
	}

	.social-links {
		margin: 0 !important;
	}

}

@media (max-width: 768px) {
	.carousel-1.carousel-caption {
		width: 60% !important;
		bottom: 20px !important;
	}

	.c-1-img {
		width: 34% !important;
		top: -11% !important;
		left: -10% !important;
	}
}

@media (max-width: 576px) {
	.navbar {
		padding: 0.5rem 0 !important;
	}

	.site-name button {
		font-size: 0.65em;
	}

	.navbar-brand span {
		font-size: 0.65rem;
	}

	.logo-placeholder span {
		font-size: 1.1rem;
	}

	.container {
		padding: 0 !important;
	}

	.d-md-block.d-lg-none.ml-auto button {
		margin-left: 1rem !important;
	}

	.sm-hide-it {
		display: none !important;
	}

	.f-align-text {
		text-align: center;
		margin-bottom: 10px;
		font-size: 0.7rem;
	}

	h1::before {
		width: 10%;
		top: 110%;
	}

	.showcase-msg-box {
		width: 95%;
	}

	.carousel-1.carousel-caption {
		width: 60% !important;
		bottom: 20px !important;
		padding: 0.5rem 0 0.5rem 3rem !important;
		font-size: 0.6rem;
	}

	.carousel-1.carousel-caption h2 {
		font-size: 1rem;
		margin: 0;
	}

	.carousel-1.carousel-caption p {
		margin-bottom: 0.5rem;
	}

	.carousel-1.carousel-caption .button-padding {
		padding: 0.3rem 1rem !important;
	}

	.c-1-img {
		width: 30% !important;
		top: -13% !important;
		left: -10% !important;
	}

	.logo-placeholder {
		margin: 1% 20% 10% !important;
	}

	.categories-list {
		margin-bottom: 1rem;
	}

	.categories-list .col-12 {
		margin-bottom: 1rem;
	}

	.donate-right {
		margin-left: 0 !important;
		margin-top: 3rem !important;
	}

	.mb-img {
		position: absolute;
		right: -5%;
	}

	.arrow-img {
		position: absolute;
		left: -14%;
		top: -1%;
		width: 13%;
	}

	.categories-padding {
		padding: 0
	}

	.let-us-logo {
		width: 95%;
		margin: 0 auto;
	}

	label[for="whatsApp-1"],
	label[for="whatsApp-2"] {
		font-size: 0.75rem;
	}

	.col-12.f-l {
		text-align: center;
	}

}

.carousel-indicators li {
	width: 10px !important;
	height: 10px !important;
	border-radius: 100%;
	background-color: #000 !important;
}

.talent-area {
	border: 1px solid #eee;
	border-width: 1px 0;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.carousel-indicators {
	bottom: -50px !important;
}

.logo-placeholder {
	margin: 1% 30%;
	width: 70%;
}

.th-title {
	font-weight: normal !important;
}

.t-h img {
	margin: 10px 0 20px;
}

.categories-list p {
	margin: 10px 0 5px !important;
	font-weight: 600;
}

.categories-list img {
	width: 100%
}

.judges .carousel-caption {
	left: 0;
	right: 0;
	top: 0
}

.whiteBg .categories-inner {
	margin: 0 1rem;
	background: #fff;
	border: 1px solid #eee;
	border-radius: 10px;
	padding: 10px;
	color: #333;
}

.judges .whiteBg h6 {
	color: #67508a;
}

.greyBg {
	background: #eee;
	padding: 2rem;
	position: relative;
}

.covid-img {
	position: absolute;
	left: -10%;
	top: -9%;
	width: 15%;
}

.ptb-5 {
	padding: 2rem 0;
}

.ptb-0 {
	padding: 0 !important
}

.button-padding {
	padding: 0.5rem 2rem;
}

a.button-padding:hover,
a.rounded-pill:hover {
	text-decoration: none;
}

h1,
.h1 {
	color: #67508a;
	text-align: center;
	position: relative;
}

.lgbg {
	background: #fcfaea;
}

@media (max-width:992px) {
	.divider {
		display: none;
	}
}

.ul-list li {
	margin-bottom: 0.5rem;
}

ol.ul-list,
ul.ul-list {
	margin-top: 0.5rem;
}

.p-1.date-field {
	color: #495057;
	padding-left: .75rem !important;
}